
/* hide recaptcha badge*/
.grecaptcha-badge {
	visibility: hidden;
}

/* show recaptcha badge */
body.recaptcha .grecaptcha-badge {
	visibility: visible;
}

.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
}

.input-error {
  border: red 2px dashed;
}

.yubilly-form {
  max-width: 400px;
}

.page-title {
  text-align: center;
}

.pagination {
  /*display: inline-block;*/

}

.pagination a {
  color: black;
  float: left;
  padding: 6px 12px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}

.pagination a:hover:not(.active) {background-color: #ddd;}

@media screen and (min-width: 600px) {
  th.merchant {
    /*font-size: calc(50% + 0.5vw + 0.5vh);*/
    max-width: 17vw;
  }
  td.merchant {
    /*font-size: calc(50% + 0.5vw + 0.5vh);*/
    /*max-width: calc(2.5rem + 12vw + 0vh);*/
    max-width: 17vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  th.email,
  td.email {
    /*font-size: calc(50% + 0.5vw + 0.5vh);*/
    /*max-width: calc(2.5rem + 18vw + 0vh);*/
    max-width: 20vW;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  th.emailFrom,
  td.emailFrom {
    /*font-size: calc(50% + 0.5vw + 0.5vh);*/
    /*max-width: calc(2.5rem + 18vw + 0vh);*/
    max-width: 25vW;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  th.date,
  td.date {
    /*font-size: calc(50% + 0.5vw + 0.5vh);*/
    /*max-width: 8rem;*/
    max-width: 25vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /*font-size: small;*/
  }
  th.total,
  td.total {
    /*font-size: calc(50% + 0.5vw + 0.5vh);*/
    /*max-width: 8rem;*/
    max-width: 20vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  th.currency,
  td.currency {
    /*font-size: small;*/
    /*font-size: calc(50% + 0.5vw + 0.5vh);*/
    max-width: 15vw;
    padding-left: 0px;
  }
  td.receipt-buttons {
    max-width: 15vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  th.in-use,
  td.in-use {
    max-width: 8vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td.merchant-button {
    max-width: 8vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (max-width: 599px) {
  th.merchant {
    font-size: calc(50% + 0.4vw + 0.4vh);
    max-width: 16vw;
  }
  td.merchant {
    font-size: calc(50% + 0.4vw + 0.4vh);
    /*max-width: calc(2.5rem + 12vw + 0vh);*/
    max-width: 16vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  th.email,
  td.email {
    font-size: calc(50% + 0.4vw + 0.4vh);
    /*max-width: calc(2.5rem + 18vw + 0vh);*/
    max-width: 20vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  th.emailFrom,
  td.emailFrom {
    font-size: calc(50% + 0.4vw + 0.4vh);
    /*max-width: calc(2.5rem + 18vw + 0vh);*/
    max-width: 22vw;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  th.date,
  td.date {
    font-size: calc(50% + 0.4vw + 0.4vh);
    /*max-width: 8rem;*/
    max-width: 15vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /*font-size: small;*/
  }
  th.total,
  td.total {
    font-size: calc(50% + 0.4vw + 0.4vh);
    /*max-width: 8rem;*/
    max-width: 19vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  th.currency,
  td.currency {
    /*font-size: small;*/
    font-size: calc(50% + 0.4vw + 0.4vh);
    max-width: 8vw;
    padding-left: 0px;
  }
  td.receipt-buttons {
    max-width: 20vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  th.in-use,
  td.in-use {
    max-width: calc(8vw);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td.merchant-button {
    max-width: calc(8vw);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}


td.label {
  font-size: calc(50% + 0.5vw + 0.5vh);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
th {
  background-color: #696969;
  color: #fff;
}
select.filter {
  /*font-size: calc(50% + 0.5vw + 0.5vh);*/
  /*min-width: calc(25% + 0.5vw + 0.5vh);
  max-width: calc(25% + 0.5vw + 0.5vh);*/
  min-width: 8rem;
  max-width: 8rem;
  margin-left: 0.1rem;
  margin-bottom: 0rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

select.filter-currency {
  /*font-size: calc(50% + 0.5vw + 0.5vh);*/
  /*min-width: calc(25% + 0.5vw + 0.5vh);
  max-width: calc(25% + 0.5vw + 0.5vh);*/
  min-width: 8rem;
  max-width: 8rem;
  margin-left: 0.1rem;
  margin-bottom: 0rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label {
  font-size: small;
}

.offer {
  width: 21rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: rgb(27, 83, 57) 2px solid;
}
.offer-header {
  text-align: center;
  font-weight: bold;
  font-size: large;
}
